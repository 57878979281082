import React, { Suspense, lazy } from "react";
import { SpinnerDotted } from "spinners-react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Outerlayout from "../layouts/outerLayout";
import Innerlayout from "../layouts/innerLayout";
import { getCookies, removeCookies } from "../hooks/useCookies";
import Settings from "../pages/settings/settings";
import ViewUsers from "../pages/users/viewUser";
import UserEdit from "../pages/users/editUser";
// import UserIndex from '../pages/users/index';
import UserCreate from "../pages/users/createUser";
import Preferences from "../pages/settings/preferences";
import CreateRoles from "../pages/roles/createRoles";
import RolesIndex from "../pages/roles/index";
import EditRoles from "../pages/roles/editRoles";
import SettingsApi from "../pages/settings/SettingsApiPage";
import SettingsLayout from "../pages/settings/settingsLayout";
import PageNotFound from "../pages/404/pageNotFound";
import PermissionDenied from "../pages/permission/permission";
import ProfileLayout from "../pages/profile/profileLayout";
// import ViewSubscription from "../pages/subscription/view";
import CreateSubscription from "../pages/subscription/create";
import KeywordsIndex from "../pages/keyword";
import ReportIndex from "../pages/report";
import ViewKeyword from "../pages/keyword/view";
import ViewReport from "../pages/report/view";
import ViewActivity from "../pages/activity/viewActivity";

const Login = lazy(() => import("../pages/logIn/logIn"));
const UserIndex = lazy(() => import("../pages/users/index"));
const UserVerification = lazy(() => import("../pages/verify/userVerfication"));
const Register = lazy(() => import("../pages/register/register"));
const Forgetpassword = lazy(() => import("../pages/passwords/forgetPassword"));
const ResetPassword = lazy(() => import("../pages/passwords/resetPassword"));
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"));
const MyForm = lazy(() =>
  import("../components/PhoneNumberBox/PhoneNumberBox")
);
// const PageNotFound = lazy(() => import('../pages/404/pageNotFound'));
const ProfilePage = lazy(() => import("../pages/profile/profilepage"));
const ChangePassword = lazy(() => import("../pages/profile/changePassword"));
const ProfileEdit = lazy(() => import("../pages/profile/profileEdits"));
const Faq = lazy(() => import("../cms/faq"));
const ViewRole = lazy(() => import("../pages/roles/view"));
const WaitListIndex = lazy(() => import("../pages/waitList/index"));
const NotificationsIndex = lazy(() => import("../pages/notifications/index"));
const ActivityIndex = lazy(() => import("../pages/activity/index"));
const ActivityCreate = lazy(() => import("../pages/activity/createActivity"));
const ActivityEdit = lazy(() => import("../pages/activity/editActivity"));
const SubscriptionIndex = lazy(() => import("../pages/subscription/index"));
// const SubscriptionEdit = lazy(() => import("../pages/subscription/edit"));
const KeywordEdit = lazy(() => import("../pages/keyword/edit"));
const CreateKeyword = lazy(() => import("../pages/keyword/create"));

export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route element={<Outerlayout />}>
          <Route
            exact
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <Login />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <UserVerification />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Register />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/forgetpassword"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        left: "50%",
                        position: "absolute",
                        textAlign: "center",
                        top: "50%",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  <Forgetpassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/reset/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route exact path="/" element={<Dashboard />} /> */}
          <Route
            exact
            path="/reset"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <UnProtectedRoute>
                  {" "}
                  <ResetPassword />
                </UnProtectedRoute>
              </Suspense>
            }
          />
        </Route>
        <Route element={<Innerlayout />}>
          <Route
            exact
            path="/roles"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <RolesIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CreateRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/edit/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <EditRoles />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/roles/viewdetails/:roleId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewRole />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/dashboard"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <Dashboard />
                </ProtectedRoute>
              </Suspense>
            }
          />

          <Route
            exact
            path="/notifications"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "400px",
                      color: "#39979d",
                    }}
                  >
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <NotificationsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/waitlist"
            element={
              <Suspense
                fallback={
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "400px",
                      color: "#39979d",
                    }}
                  >
                    <SpinnerDotted />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <WaitListIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user/edit/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/text"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <MyForm />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/user"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <UserIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/activity"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ActivityIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/activity/viewdetails/:activityId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewActivity />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/activity/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ActivityCreate />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/activity/edit/:activityId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ActivityEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/subscriptions"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <SubscriptionIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/subscriptions/viewdetails/:userId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewUsers />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/subscriptions/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CreateSubscription />
                </ProtectedRoute>
              </Suspense>
            }
          />
          {/* <Route
            exact
            path="/subscriptions/edit/:subscriptionId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <SubscriptionEdit />
                </ProtectedRoute>
              </Suspense>
            }
          /> */}
          <Route
            exact
            path="/keywords"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <KeywordsIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/keywords/viewdetails/:keywordId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewKeyword />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/keywords/create"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <CreateKeyword />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/keywords/edit/:keywordId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <KeywordEdit />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/report"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ReportIndex />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route
            exact
            path="/report/viewdetails/:reportId"
            element={
              <Suspense
                fallback={
                  <div>
                    <SpinnerDotted
                      style={{
                        marginTop: "250px",
                        marginLeft: "300px",
                        color: "#39979d",
                      }}
                    />
                  </div>
                }
              >
                <ProtectedRoute>
                  {" "}
                  <ViewReport />
                </ProtectedRoute>
              </Suspense>
            }
          />
          <Route element={<SettingsLayout />}>
            <Route
              exact
              path="/settings"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Settings />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/preferences"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Preferences />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/api"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <SettingsApi />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
          <Route element={<ProfileLayout />}>
            <Route
              exact
              path="/profile"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ProfilePage />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/profile-edit"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ProfileEdit />
                  </ProtectedRoute>
                </Suspense>
              }
            />
            <Route
              exact
              path="/cms"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{ marginTop: "250px", marginLeft: "300px" }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <Faq />
                  </ProtectedRoute>
                </Suspense>
              }
            />

            <Route
              exact
              path="/changePassword"
              element={
                <Suspense
                  fallback={
                    <div>
                      <SpinnerDotted
                        style={{
                          marginTop: "250px",
                          marginLeft: "300px",
                          color: "#39979d",
                        }}
                      />
                    </div>
                  }
                >
                  <ProtectedRoute>
                    {" "}
                    <ChangePassword />
                  </ProtectedRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      marginTop: "250px",
                      marginLeft: "300px",
                      color: "#39979d",
                    }}
                  />
                </div>
              }
            >
              {" "}
              <PageNotFound />
            </Suspense>
          }
        />
        <Route
          exact
          path="/permission"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerDotted
                    style={{
                      marginTop: "250px",
                      marginLeft: "300px",
                      color: "#39979d",
                    }}
                  />
                </div>
              }
            >
              <ProtectedRoute>
                {" "}
                <PermissionDenied />
              </ProtectedRoute>
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ children }) {
  if (document.cookie && getCookies("Token")) {
    if (getCookies("USERPERMISSION")) {
      return children;
    }
    removeCookies("Token");
    removeCookies("refreshToken");
    sessionStorage.setItem("cookiesCleared", true);
    return <Navigate to="/login" />;
  }
  sessionStorage.setItem("cookiesCleared", true);
  return <Navigate to="/login" />;
}

// eslint-disable-next-line react/prop-types
function UnProtectedRoute({ children }) {
  return getCookies("Token") ? <Navigate to="/dashboard" /> : children;
}
