/* eslint-disable react/function-component-definition */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { toast } from "react-toastify";
import TextInput from "../../components/TextField/TextField";
import { Entry } from "../../redux/entrySlice";
import { TableContext } from "../../contexts/tableContext";
import styles from "./create.module.scss";
import NumberInput from "../../components/NumberBox/NumberBox";
import TextAreaField from "../../components/TextArea/TextArea";

const SubscriptionCreate = React.memo(() => {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const [formIsReset, setFormIsReset] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader("Add Subscription Details");
  }, []);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);
    const skills = [];
    if (data.skills) {
      for (let i = 0; i < data.skills.length; i += 1) {
        skills.push(data.skills[i]);
      }
    }

    const apiData = {
      title: data.title,
      description: data.description,
      priceDetails: [
        {
          duration: "Monthly",
          price: data.monthly,
        },
        {
          duration: "HalfYearly",
          price: data.halfyearly,
        },
        {
          duration: "Yearly",
          price: data.yearly,
        },
      ],
    };
    data.actionUrl = "subscriptions";
    data.actionMethod = "post";
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success("Subscription creation successful");
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError("Something went wrong!");
      }
    });
  }

  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ height: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Title"
                  fieldName="title"
                  placeHolder="Enter Title"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                  charactersOnly
                  resetCount={formIsReset}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <TextAreaField
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Description"
                  fieldName="description"
                  placeHolder="Enter Description"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                  resetCount={formIsReset}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Monthly"
                  fieldName="monthly"
                  placeHolder="Enter Monthly Price"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                  resetCount={formIsReset}
                />
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="HalfYearly"
                  fieldName="halfyearly"
                  placeHolder="Enter HalfYearly Price"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                  resetCount={formIsReset}
                />
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12">
                <NumberInput
                  className={styles.inputbox}
                  classNamedark={styles.inputbox1}
                  labelstyle={styles.formlabel}
                  label="Yearly"
                  fieldName="yearly"
                  placeHolder="Enter Yearly Price"
                  register={register}
                  errors={errors}
                  isRequired
                  mandatory
                  resetCount={formIsReset}
                />
              </div>
              <input
                className={styles.formbtn}
                type="submit"
                disabled={submiting}
                value={submiting ? "Please wait..." : "Submit"}
              />
              <input
                className={styles.resetbtn}
                type="button"
                value="Reset"
                onClick={() => {
                  setFormIsReset(true);
                  reset();
                }}
              />
              {error && <h6 className={styles[errormsgStyle]}>{error}</h6>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SubscriptionCreate;
