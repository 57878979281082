/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./App.css";
import Routers from "./router/index";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContextProvider from "./contexts/tableContext";
import { getCookies, setCookies } from "./hooks/useCookies";
import envValues from "./enviornment";

function App() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [showBanner, setShowBanner] = useState(true);
  const cookieBannerControl = () => {
    setShowBanner(!showBanner);
    setCookies("showCookieBanner", false);
  };
  useEffect(() => {
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    console.log(connection);
    console.log(window.navigator);
    const handleOnlineStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };

    // Add event listeners for online/offline events
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [window.navigator]);
  return (
    <Provider store={store}>
      {isOnline && (
        <div className="App">
          <TableContextProvider>
            <Routers />
          </TableContextProvider>
          <ToastContainer autoClose={2000} />
        </div>
      )}
      {showBanner &&
      envValues.REACT_APP_SHOW_COOKIE_BOX &&
      getCookies("showCookieBanner") !== "false" ? (
        <div className="cookie-banner">
          <p>This website uses cookies to enhance user experience.</p>
          <button type="button" className="close" onClick={cookieBannerControl}>
            I Understand
          </button>
        </div>
      ) : null}
      {!isOnline && (
        <div className="offline-banner">
          <p>
            You are currently offline. Please check your internet connection.
          </p>
        </div>
      )}
    </Provider>
  );
}
export default App;
