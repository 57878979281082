/* eslint-disable import/no-mutable-exports */
let env = process.env.REACT_APP_ENV;
if (!env)
  if (typeof window !== "undefined") {
    switch (window.location.host) {
      case "manage-skidule.iscriptsdemo.com":
        env = "staging";
        break;
      case "manage.skiduleapp.com":
        env = "production";
        break;
      default:
        env = "development";
    }
  } else {
    env = "development";
  }
let envValues = {
  PORT: 80,
  REACT_APP_API_ENDPOINT: "http://localhost:4075/v1",
  REACT_APP_API_FRONT_END: "http://localhost:3000",
  REACT_APP_API_PERMISSION_DENIED: "http://localhost:3000/permission",
  REACT_APP_SHOW_COOKIE_BOX: false,
  SOCKET_STATUS: "ON",
  COLLECTIONURL: "notifications",
};
switch (env) {
  case "staging":
    envValues = {
      PORT: 3075,
      REACT_APP_API_ENDPOINT: "https://api-skidule.iscriptsdemo.com/v1",
      REACT_APP_API_FRONT_END: "https://manage-skidule.iscriptsdemo.com/",
      REACT_APP_API_PERMISSION_DENIED:
        "https://manage-skidule.iscriptsdemo.com//permission",
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: "OFF",
      COLLECTIONURL: "notifications",
    };
    break;
  case "production":
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: "https://api.skiduleapp.com/v1",
      REACT_APP_API_FRONT_END: "https://manage.skiduleapp.com",
      REACT_APP_API_PERMISSION_DENIED:
        "https://manage.skiduleapp.com/permission",
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: "OFF",
      COLLECTIONURL: "notifications",
    };
    break;
  case "development":
    envValues = {
      PORT: 80,
      REACT_APP_API_ENDPOINT: "http://localhost:4075/v1",
      REACT_APP_API_FRONT_END: "http://localhost:3000",
      REACT_APP_API_PERMISSION_DENIED: "http://localhost:3000/permission",
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: "ON",
      COLLECTIONURL: "notifications",
    };
    break;
  default:
    envValues = {
      PORT: 3075,
      REACT_APP_API_ENDPOINT: "http://localhost:4075/v1",
      REACT_APP_API_FRONT_END: "http://localhost:3000",
      REACT_APP_API_PERMISSION_DENIED: "http://localhost:3000/permission",
      REACT_APP_SHOW_COOKIE_BOX: false,
      SOCKET_STATUS: "OFF",
      COLLECTIONURL: "notifications",
    };
    break;
}

export default envValues;
