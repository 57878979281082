/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */
import axios from "axios";
// import Avatar from 'react-avatar';
import React, { useEffect, useState, useContext } from "react";
import { Col, Row, Image, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { SpinnerDotted } from "spinners-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/fontawesome-free-solid";
import { getCookies } from "../../hooks/useCookies";
import { TableContext } from "../../contexts/tableContext";
import styles from "./viewUser.module.scss";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import envValues from "../../enviornment";

function ViewUsers() {
  const defaultPic =
    "https://boilerplate-armia.s3.us-west-2.amazonaws.com/downloads/blank-profile-picture-973460_960_720.webp-1671689291091";
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const params = useParams();
  let { userId } = params;
  const token = `Bearer ${getCookies("Token")}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const dateFormat = getCookies("dateFormat");
  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/user/${userId}`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  console.log(details, "details");
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    userId = params.userId;
    getUserDetails();
    setDashboardHeader("User Details");
  }, []);
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: "100vh" }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: "#39979d",
                left: "50%",
                position: "relative",
                textAlign: "center",
                top: "50%",
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row>
                <Col lg={12}>
                  <div className={styles.profilepicdiv}>
                    {details.image ? (
                      <Image
                        src={details.image}
                        alt={details.username}
                        className={styles.profilepic}
                      />
                    ) : (
                      <Image
                        src={defaultPic}
                        alt={
                          details.username
                            ? details.username
                            : details.name
                            ? details.name
                            : "User"
                        }
                        className={styles.profilepic}
                      />
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>First Name</span>
                          <p className={styles.empname}>
                            {details.firstName === undefined || ""
                              ? "N/A"
                              : details?.firstName}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Last Name</span>
                          <p className={styles.empname}>{details.lastName}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Email</span>
                          <p className={styles.empname}>
                            {details.email === undefined || ""
                              ? "N/A"
                              : details?.email}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Phone Number</span>
                          <p className={styles.empname}>
                            {details.phoneNumber === undefined || ""
                              ? "N/A"
                              : details?.phoneNumber}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>State</span>
                          <p className={styles.empname}>
                            {details.state === undefined || ""
                              ? "N/A"
                              : details?.state}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Street</span>
                          <p className={styles.empname}>
                            {details.street === undefined || ""
                              ? "N/A"
                              : details?.street}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Status</span>
                          <p className={styles.empname}>
                            {details.active ? "Active" : "InActive"}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div
                          className={styles[viewformStyle]}
                          id={styles.empdiv}
                        >
                          <span className={styles.title}>Join Date</span>
                          <p className={styles.empname}>
                            {details.createdAt
                              ? DateFormatter(
                                  details.createdAt,
                                  dateFormat || "toDateTime"
                                )
                              : "N/A"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewUsers;
