/* eslint-disable react/function-component-definition */
import React, { useEffect, useContext } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import styles from "./index.module.scss";
import CustomTable from "../../components/CustomTable/CustomTable";
import { REACT_APP_API_FETCH_KEYWORDS } from "../../redux/apiConstants";
import { TableContext } from "../../contexts/tableContext";

const KeywordsIndex = React.memo(() => {
  const { setDashboardHeader } = useContext(TableContext);
  useEffect(() => {
    setDashboardHeader("Keywords");
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.indexdiv}>
      <CustomTable
        urlParam={REACT_APP_API_FETCH_KEYWORDS}
        // viewPopUp
        section="keywords"
        deleteCondition={{ checkCondition: false, apiUrl: "www.google.com" }}
      />
    </div>
  );
});
export default KeywordsIndex;
