/* eslint-disable no-constant-condition */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import PropTypes, { bool } from "prop-types";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { TableContext } from "../../contexts/tableContext";
// import { SpinnerRoundFilled } from 'spinners-react';
import ActionButtons from "../ActionsButtons/ActionButtons";
import Pagination from "../Pagination/Pagination";
import ToggleButtonOnOff from "../ToggleButton/ToggleButton";
// import ActionSelection from '../ActionsButtons/ActionSelection';
import styles from "./CustomTable.module.scss";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getCookies } from "../../hooks/useCookies";
import { DateFormatter } from "../../utilityFunctions/utilsFunctions";
import { SERVER_BASE_URL } from "../../redux/apiConstants";

const CustomTable = React.memo(
  ({
    urlParam,
    viewPopUp,
    toggleButton,
    section,
    dataPerPage,
    deleteCondition,
  }) => {
    let url;
    const { dashboardStyle } = useContext(TableContext);
    const navigate = useNavigate();
    const { bodyStyle } = useContext(TableContext);
    const [addButtonPermissions, setAddButtonPermissions] = useState({});
    const { bodyheader } = useContext(TableContext);
    const { searchboxStyle } = useContext(TableContext);
    const { tableheadStyle } = useContext(TableContext);
    const { tablebodyStyle } = useContext(TableContext);
    const [tableData, setTableData] = useState([]);
    const dispatch = useDispatch();
    const { pageNum, setPageNum, deletedData, setDeletedData } =
      useContext(TableContext);
    // new
    const [checkedState, setCheckedState] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const token = `Bearer ${getCookies("Token")}`;
    const [sorting, setSorting] = useState([{ col: "none", sortType: "none" }]);
    const [paginationData, setPaginationData] = useState([
      { page: 0, totalResult: 0, totalPages: 0 },
    ]);
    const [search, setSearchTerm] = useState(null);
    const [timer, setTimer] = useState(null);
    const [columns, setColumns] = useState([]);
    // const [deletedData, setDeletedData] = useState(null);
    const [selected, setselected] = useState([]);
    const [stockCount, setStockCount] = useState(0);
    const [tableLoading, setTableLoading] = useState(true);
    const dateFormat = getCookies("dateFormat");
    const historical = "historical";
    const sectionStock = "stock";
    const sectionWaitlist = "waitlist";
    const selectText = "Select";
    let response;
    let statusCode;
    const handleOnChange = (id, position) => {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      const updatedCheckedState = checkedState.map((item, indexed) =>
        indexed === position ? !item : false
      );
      setCheckedState(updatedCheckedState);
      setselected(id);
      if (selected === id) {
        setselected([]);
      }
    };
    const actionAdd = () => {
      navigate(`/${section.toLowerCase()}/create/`);
    };
    const handleOnChangeAll = () => {
      setCheckedAll(!checkedAll);
      const newarray = [];
      if (checkedAll === false) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    };
    const getUsers = async () => {
      setTableLoading(true);
      url = urlParam;
      url += `page=${pageNum}&limit=${dataPerPage}`;
      if (sorting.sortType && sorting.sortType !== "none") {
        url += `&sortBy=${sorting.col}:${sorting.sortType}`;
      }
      if (search !== null) {
        const encodedSearchTerm = encodeURIComponent(search);
        url += `&searchBy=${encodedSearchTerm}`;
      }
      axios
        .get(url, {
          method: "GET",
          headers: { Authorization: token },
        })
        .then((resp) => {
          console.log(resp, "resppp");
          setTableLoading(false);
          statusCode = resp.status;
          return resp;
        })
        .then((res) => {
          if (statusCode === 200) {
            response = res.data.data.result;
            setTableData(response.results);
            setPaginationData({
              page: response.page,
              totalResult: response.totalResults,
              totalPages: response.totalPages,
            });
            setCheckedState(new Array(response.results.length).fill(false));
            setColumns(response.Displayfields);
            if (section === sectionStock)
              setStockCount(res.data.data.stockCount);
          }
        })
        .catch((err) => {
          setTableLoading(false);
          return err;
        });
    };
    useEffect(() => {
      setSearchTerm(null);
      setPageNum(1);
    }, []);
    useEffect(() => {
      const temp = section.replace(/\s+/g, "").toLowerCase();
      const permissions = getCookies("USERPERMISSION");
      permissions.forEach((val) => {
        if (val.section.toLowerCase() === temp) {
          setAddButtonPermissions({
            view: val.view ? val.view : false,
            edit: val.edit ? val.edit : false,
            create: val.create ? val.create : false,
            delete: val.delete ? val.delete : false,
            list: !!(val.view || val.edit || val.create || val.delete),
          });
        } else if (val.submenu) {
          val.submenu.forEach((element) => {
            if (element.section.toLowerCase() === temp) {
              setAddButtonPermissions({
                view: element.view ? element.view : false,
                edit: element.edit ? element.edit : false,
                create: element.create ? element.create : false,
                delete: element.delete ? element.delete : false,
                list: !!(
                  element.view ||
                  element.edit ||
                  element.create ||
                  element.delete
                ),
              });
            }
          });
        }
      });
      getUsers();
      const newarray = [];
      if (checkedAll) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          newarray.push(tableData[i].id);
          checkedState[i] = true;
        }
        setselected(newarray);
      } else {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < tableData.length; i++) {
          checkedState[i] = false;
        }
        setselected([]);
      }
    }, [pageNum, search, sorting, deletedData]);
    const setSort = (param) => () => {
      if (param === "date") {
        param = "createdAt";
      }
      setPageNum(1);
      switch (sorting.sortType) {
        case "none":
          setSorting({ col: param, sortType: "asc" });
          break;
        case "asc":
          setSorting({ col: param, sortType: "desc" });
          break;
        case "desc":
          setSorting({ col: param, sortType: "none" });
          break;
        default:
          setSorting({ col: param, sortType: "asc" });
          break;
      }
    };
    const onFilterTextChange = (e) => {
      const even = e;
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
      setTimer(
        setTimeout(() => {
          const { value } = even.target;
          if (value.trim().length > 0) {
            setPageNum(1);
            const trimmedValue = even.target.value.trim().replace(/\s+/g, " ");
            setSearchTerm(trimmedValue);
          } else {
            setSearchTerm(null);
          }
          even.target.value = value.trim().replace(/\s+/g, " ");
        }, 1000)
      );
    };
    console.log(stockCount);
    return (
      <div className={styles[bodyStyle]}>
        {addButtonPermissions.list && (
          <div
            className={styles[dashboardStyle]}
            style={{ minHeight: "100vh" }}
            id={styles.dashboardcont2}
          >
            <div className="container-fluid" id={styles.container}>
              <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12">
                  <div className={styles.search_box}>
                    <input
                      className={styles[searchboxStyle]}
                      id={styles.searchbar}
                      type="search"
                      onChange={onFilterTextChange}
                      placeholder={
                        section === "user"
                          ? "Search by FirstName, lastName, Email, or Address"
                          : section === sectionWaitlist
                          ? "Search by Email"
                          : section === sectionStock
                          ? "Search by Symbol, Name, Sector or Index"
                          : section === historical
                          ? "Search by Stock Name"
                          : section === "referal"
                          ? "Search by Username, Email, Referal Link or Joined User"
                          : section === "subscriptions"
                          ? "Search by FirstName, lastName, Email, or Address"
                          : section === "report"
                          ? "Search by User Name"
                          : section === "keywords"
                          ? "Search by Name"
                          : section === "activity"
                          ? "Search by Name, Description"
                          : "Search"
                      }
                      // value={search}
                      readOnly={tableLoading}
                    />
                    <div
                      className={
                        tableLoading ? "spinner-border spinner-border-sm" : ""
                      }
                      id={styles.spinner}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12" />
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className={styles.sectionright}>
                    {section === sectionStock && (
                      <div className="dropdown" id={styles.dropdown}>
                        <button
                          className="btn btn-secondary"
                          type="button"
                          onClick={() => {
                            if (
                              selected.length === 0 ||
                              selected === undefined ||
                              selected === ""
                            ) {
                              if (stockCount === 0)
                                toast.error(
                                  "Please select a stock to continue."
                                );
                              else
                                toast.error(
                                  `You have already selected a stock for tomorrow's prediction.`
                                );
                            } else if (stockCount > 0) {
                              toast.error(
                                `You have already selected a stock for tomorrow's prediction.`
                              );
                            } else {
                              confirmAlert({
                                title: `Tomorrow's Stock Selection`,
                                message: `Are you sure you want this stock for tomorrow's prediction?`,
                                buttons: [
                                  {
                                    label: "Yes",
                                    onClick: () => {
                                      axios
                                        .get(
                                          `${SERVER_BASE_URL}/stock/select-stock/${selected}`,
                                          {
                                            headers: { Authorization: token },
                                          }
                                        )
                                        .then((resp) => {
                                          if (resp.data.code === "200") {
                                            toast.success(
                                              `Stock successfully added for tomorrow's prediction`
                                            );
                                            setDeletedData(
                                              `data deleted ${selected}`
                                            );
                                            setselected([]);
                                            setStockCount(1);
                                            window.location.reload(false);
                                          } else {
                                            toast.error(resp.data.data);
                                            setCheckedState(
                                              new Array(tableData.length).fill(
                                                false
                                              )
                                            );
                                            setselected([]);
                                          }
                                        })
                                        .catch((e) => {
                                          console.log(e);
                                          return e;
                                        });
                                    },
                                  },
                                  {
                                    label: "No",
                                    // onClick: () => alert("Click No")
                                  },
                                ],
                              });
                            }
                          }}
                        >
                          Select Stock
                        </button>
                      </div>
                    )}
                    {addButtonPermissions.create && (
                      <button
                        className={styles.tablebtn}
                        type="button"
                        onClick={() => {
                          actionAdd();
                        }}
                      >
                        {" "}
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={styles.add_icon}
                        />
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="table-responsive" style={{ minHeight: "250px" }}>
                <table className="table table-borderless">
                  {columns.length > 0 && (
                    <thead>
                      <tr className={styles[tableheadStyle]}>
                        {section === sectionStock && (
                          <th scope="col">{selectText.toUpperCase()}</th>
                        )}
                        {columns.map((item) => (
                          <th scope="col">
                            {/* {console.log(item, "vncbxnmcbm")}{" "} */}
                            {item === "type"
                              ? "INDEX"
                              : item === "total_predictions"
                              ? "TOTAL PREDICTIONS"
                              : item === "refered_by"
                              ? "USERNAME"
                              : item === "referred_email"
                              ? "EMAIL"
                              : item === "referal_link"
                              ? "REFERAL LINK"
                              : item === "joined_email"
                              ? "JOINED USER"
                              : item === "mean"
                              ? "MEDIAN"
                              : item === "sd"
                              ? "STD. DEVIATION"
                              : item === "closing_price"
                              ? "CLOSING PRICE"
                              : item === "duration"
                              ? "YEARLY"
                              : item === "predicted_date"
                              ? "PREDICTION DATE"
                              : item.toUpperCase().replace(/_/, " ")}
                            {console.log(item, "itemmmm")}
                            {item !== "Monthly" &&
                              item !== "Yearly" &&
                              item !== "HalfYearly" && (
                                <button
                                  className={styles.sortbtn}
                                  type="button"
                                  aria-label="Sort button"
                                  onClick={setSort(item)}
                                >
                                  {sorting.sortType === "asc" &&
                                  sorting.col === item ? (
                                    <i className="fa fa-arrow-up" />
                                  ) : sorting.sortType === "desc" &&
                                    sorting.col === item ? (
                                    <i className="fa fa-arrow-down" />
                                  ) : (
                                    <i className="fa fa-sort" />
                                  )}
                                </button>
                              )}
                          </th>
                        ))}
                        {Object.values(addButtonPermissions).filter(Boolean)
                          .length === 2 ? (
                          section === "referal" ? (
                            ""
                          ) : (
                            <th scope="col">ACTION</th>
                          )
                        ) : (
                          <th scope="col">ACTIONS</th>
                        )}
                      </tr>
                    </thead>
                  )}
                  {Object.keys(tableData) && !tableLoading ? (
                    <tbody>
                      {tableData.map((item, index) => (
                        <tr className={styles[tablebodyStyle]}>
                          {section === sectionStock ? (
                            <th scope="row">
                              <div className="left-section">
                                <input
                                  type="checkbox"
                                  id={`custom-checkbox-${item}`}
                                  name={item.id}
                                  value={item.id}
                                  checked={checkedState[index]}
                                  onChange={() =>
                                    handleOnChange(item.id, index)
                                  }
                                />
                              </div>
                            </th>
                          ) : (
                            ""
                          )}
                          {columns.map((ele) => (
                            <td>
                              {console.log(ele, "asd")}
                              {ele === toggleButton.field ? (
                                <ToggleButtonOnOff
                                  value={item[ele]}
                                  id={item.id}
                                  placeholders={["Active", "Inactive"]}
                                />
                              ) : typeof item[ele] === "boolean" ? (
                                <h6 className="order_date">
                                  {" "}
                                  String(item[ele])
                                </h6>
                              ) : ele === "name" && section === "user" ? (
                                <p>
                                  {item?.name === undefined || ""
                                    ? "N/A"
                                    : item?.name}
                                </p>
                              ) : ele === "age" && section === "user" ? (
                                <p>
                                  {item?.age === undefined || ""
                                    ? "N/A"
                                    : item?.age}
                                </p>
                              ) : ele === "industry" && section === "user" ? (
                                <p>
                                  {item?.industry === undefined || ""
                                    ? "N/A"
                                    : item?.industry}
                                </p>
                              ) : ele === "HalfYearly" &&
                                section === "subscriptions" ? (
                                <p>{item.HalfYearly.price}</p>
                              ) : ele === "Monthly" &&
                                section === "subscriptions" ? (
                                <p>
                                  <p>{item.Monthly.price}</p>
                                </p>
                              ) : ele === "Yearly" &&
                                section === "subscriptions" ? (
                                <p>
                                  <p>{item.Yearly.price}</p>
                                </p>
                              ) : ele === "price" &&
                                section === "subscriptions" ? (
                                <p>
                                  {item?.priceDetails.map((pricedetail) => (
                                    <p>{pricedetail?.price}</p>
                                  ))}
                                </p>
                              ) : ele === "username" && section === "user" ? (
                                <p>
                                  {item?.username === undefined || ""
                                    ? "N/A"
                                    : item?.username}
                                </p>
                              ) : ele === "stock" && section === historical ? (
                                <p>{item?.stock}</p>
                              ) : ele === "mean" && section === historical ? (
                                // eslint-disable-next-line use-isnan
                                <p>
                                  {item?.mean !== "NaN"
                                    ? `${item?.mean}%`
                                    : "N/A"}
                                </p>
                              ) : ele === "sd" && section === historical ? (
                                // eslint-disable-next-line use-isnan
                                <p>
                                  {item?.sd !== "NaN"
                                    ? `${
                                        item?.sd === "0"
                                          ? 0
                                          : parseFloat(item?.sd).toFixed(2)
                                      }%`
                                    : "N/A"}
                                </p>
                              ) : ele === "predicted_date" &&
                                section === historical ? (
                                <p>
                                  {DateFormatter(
                                    item?.predicted_date,
                                    dateFormat || "toDate"
                                  )}
                                </p>
                              ) : ele === "closing_price" &&
                                section === "stock" ? (
                                <p>
                                  {item?.type === "S&P 500" ? "$" : "£"}
                                  {item?.closing_price}
                                </p>
                              ) : ele === "closing_price" &&
                                section === "historical" ? (
                                <p>
                                  {item?.CurrencySymbol === "p" ? "£" : "$"}
                                  {item?.closing_price}
                                </p>
                              ) : ele === "date" &&
                                section === sectionWaitlist ? (
                                <p>
                                  {DateFormatter(
                                    item?.createdAt,
                                    dateFormat || "toDateTime"
                                  )}
                                </p>
                              ) : (
                                item[ele]
                              )}
                            </td>
                          ))}
                          {section === "referal" ? (
                            ""
                          ) : (
                            <td>
                              {!(
                                section === "activity" && item.name === "other"
                              ) && (
                                <ActionButtons
                                  data={item}
                                  viewPopUp={viewPopUp}
                                  section={section}
                                />
                              )}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <h6>Loading...</h6>
                  )}
                  {tableData.length === 0 && !tableLoading ? (
                    <h6 className={styles.norecord}>No record found</h6>
                  ) : (
                    ""
                  )}
                </table>
              </div>
              <Pagination
                paginationData={paginationData}
                setPageNum={setPageNum}
              />
              <br />
              <br />
            </div>
          </div>
        )}
      </div>
    );
  }
);
CustomTable.propTypes = {
  urlParam: PropTypes.string,
  viewPopUp: PropTypes.bool,
  toggleButton: PropTypes.oneOfType([PropTypes.object]),
  deleteCondition: PropTypes.oneOfType([PropTypes.object]),
  section: PropTypes.string,
  dataPerPage: PropTypes.number,
};
CustomTable.defaultProps = {
  viewPopUp: false,
  toggleButton: { show: false, field: "none" },
  deleteCondition: { checkCondition: false, apiUrl: "none" },
  section: "User",
  urlParam: null,
  dataPerPage: 10,
};
export default CustomTable;
