/* eslint-disable global-require */
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TableContext } from "../../contexts/tableContext";
import pagenotfoundBg from "../../assets/404page.svg";
import styles from "./pageNotFound.module.scss";
import envValues from "../../enviornment";
import { getCookies } from "../../hooks/useCookies";

function PageNotFound() {
  const token = `Bearer ${getCookies("Token")}`;
  const [userMenus, setUserMenus] = useState([]);
  const navigate = useNavigate();
  const { bodyStyle } = useContext(TableContext);
  const handleButtonClick = () => {
    if (userMenus.length > 0) navigate(userMenus[0]?.urlPath);
    else navigate("/");
  };
  useEffect(() => {
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/notifications/getUserMenu`, {
        method: "GET",
        headers: { Authorization: token },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setUserMenus(res.data.data.userMenu);
        }
      })
      .catch((err) => err);
  }, []);
  return (
    <div className={styles[bodyStyle]} id={styles.notfoundbg}>
      <div className={styles.centerbox}>
        <div className={styles.imgdiv}>
          <Image
            src={pagenotfoundBg}
            alt="404image"
            className={styles.pagenotimage}
          />
        </div>
        <div>
          <h2 className={styles.pagenotfoundhead}>Page Not Found</h2>
          <p className={styles.pagenotfoundpara}>
            We are sorry, the page you requested could not be found.
            <br />
            Please go back to the homepage
          </p>
        </div>
        <div className={styles.btnsection}>
          <button
            type="button"
            className={styles.gohomebtn}
            onClick={() => {
              handleButtonClick();
            }}
          >
            Go Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
