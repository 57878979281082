import { createSlice } from "@reduxjs/toolkit";

export const SocketSlice = createSlice({
  name: "notification",
  initialState: {
    notificationCount: 0,
  },
  reducers: {
    updateNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },
  },
});

export const { updateNotificationCount } = SocketSlice.actions;

export default SocketSlice.reducer;
